const states_territories = [
  'alabama',
  'alaska',
  'arizona',
  'arkansas',
  'california',
  'colorado',
  'connecticut',
  'delaware',
  'district_of_columbia',
  'florida',
  'georgia',
  'guam',	
  'hawaii',
  'idaho',
  'illinois',
  'indiana',
  'iowa',
  'kansas',
  'kentucky',
  'louisiana',
  'maine',
  'maryland',
  'massachusetts',
  'michigan',
  'minnesota',
  'mississippi',
  'missouri',
  'montana',
  'nebraska',
  'nevada',
  'new_hampshire',
  'new_jersey',
  'new_mexico',
  'new_york',
  'north_carolina',
  'north_dakota',
  'ohio',
  'oklahoma',
  'oregon',
  'pennsylvania',
  'puerto_rico',
  'rhode_island',
  'south_carolina',
  'south_dakota',
  'tennessee',
  'texas',
  'utah',
  'vermont',
  'virgin_islands',
  'virginia',
  'washington',
  'west_virginia',
  'wisconsin',
  'wyoming'
]

export default function stateValues (occ) {
  if (occ) {
    return states_territories.map(k => (occ[k + '_perthousand']))
  } else {
    return null
  }
}
